export const siteColors = {
  primary: {
    dark: '#00332f',
    mid: '#00534C',
    light: '#008075',
  },
  secondary: {
    dark: '#ffb24d', //65
    mid: '#FF9810',
    light: '#ffa733', //60
  },
};
export const config = {
  rootUrl: 'https://membership.wallabies.rugby',
};
